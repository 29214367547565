import { website } from '@/axios';
export const shippingCompaniesLink = 'admin/shipping-company';

export default {
  getCompanies () {
    return website().get(shippingCompaniesLink);
  },
  getShippingCompany (id) {
    return website().get(`${ shippingCompaniesLink }/${ id }`);
  },
  createShippingCompany (data) {
    return website().post(shippingCompaniesLink, data);
  },
  updateShippingCompany (id, data) {
    return website().put(`${ shippingCompaniesLink }/${ id }`, data);
  },
  deleteShippingCompany (id) {
    return website().delete(`${ shippingCompaniesLink }/${ id }`);
  }
};
