<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({ name: 'company.add' })">{{
      $t("shippingCompanies.title")
    }}</dashboard-page-title>

    <main-table :fields="fields" :items="companies"></main-table>
  </div>
</template>
<script>
import companiesServices from "../services";
export default {
  data() {
    return {
      companies: [],
      fields: [
        { label: this.$t("main.name"), key: "name" },
        {
          label: this.$t("shippingCompanies.refNumber"),
          key: "ref_id",
        },
        {
          label: this.$t("shippingCompanies.balanceAmount"),
          key: "walelt.balance",
        },
        {
          label: this.$t("main.actions"),
          key: "actions",
          type: "actions",
          actions: [
            {
              text: this.$t("main.update"),
              icon: "ri-ball-pen-fill",
              color: "info",
              ifNavigate: true,
              routeName: "company.update",
              routeParams: ["id"],
            },
          ],
        },
      ],
    };
  },
  methods: {
    async getCompanies() {
      const { data } = await companiesServices.getCompanies();
      this.companies = data;
    },
  },
  created() {
    this.getCompanies();
  },
};
</script>
